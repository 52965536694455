import React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import './App.scss'
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  ...(null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
        <Auth0Provider {...providerConfig}>
                <App />
        </Auth0Provider>,
        document.getElementById("root")
);

