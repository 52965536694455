import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import TeamItem from '../team-item';

Team.propTypes = {
    data: PropTypes.array,
};

function Team(props) {

    const {data, isHome, currentIndex, setCurrentIndex} = props;

    const [dataBlock] = useState({
        subtitle: 'Our team',
        title: 'Meet our TEAM',
    })

    useEffect(() => {
        if (!currentIndex && isHome){
            window.scrollTo(0, 0)
        } else if (!isHome && currentIndex > 0) {
            window.scrollTo(1000, 1560)
        }
      }, [isHome, currentIndex])

    return (
        <section className="tf-section tf-team home-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-51" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                    {
                        data.map((item, index) => (
                            <div key={item.id} onClick={() => setCurrentIndex(index)} className="col-xl-3 col-md-6 bossy" data-aos="fade-up" data-aos-duration="800">
                                {isHome ? (<Link to='/about'><TeamItem item={item}/></Link>) : (
                                <AnchorLink href="#squad">
                                    <TeamItem item={item}/>
                                </AnchorLink>
                                )}
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Team;