import React from 'react';
import ClaimLunarList from "../../images/chips/claim.gif";

const Trigger = ({ buttonRef, showModal, moolah, hasMustard }) => {
  return moolah >= 1000000 && !hasMustard ? (
        <>
          <img
            alt="claim lunar list"
            className="lunar-list shadowfilter"
            src={ClaimLunarList}
            ref={buttonRef}
            onClick={showModal}
          ></img>
        </>
      ): null
;}
export default Trigger;
