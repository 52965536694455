import React from 'react';
import PageTitle from '../components/pagetitle';


function Contact(props) {
    // const [name, setName] = useState("");
    // const [mail, setMail] = useState("");
    // const [phone, setPhone] = useState("");

    // const handleSubmit = (event) => {
    //     fetch("/", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         body: encode({ "form-name": "contact", "name": name, "mail": mail, "phone": phone })
    //     })
    //         .then(() => alert("Success!"))
    //         .catch(error => alert(error));
    
    //     event.preventDefault();
    // };

    return (
        <div>
            <PageTitle title='Collaboration Requests' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="content-about m-b50 mobie-40" data-aos="fade-right" data-aos-duration="800">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title">Want to be a partner?</h4>
                                </div>
                                <p className="m-r-40">Tell us more about your project</p>
                            </div>
                            <form name="contact" method="post" data-netlify="true" className="form-contact" id="contactform" data-aos="fade-right" data-aos-duration="800">
                                <input type="hidden" name="form-name" value="contact" />
                                <fieldset>
                                    <input type="text" name="name" id="name" placeholder="Your Name"/>
                                </fieldset>
                                <fieldset>
                                    <input type="email" name="mail" id="mail" placeholder="Email Address"/>
                                </fieldset>
                                <fieldset>
                                    <input type="number" name="phone" id="phone" placeholder="Phone Number"/>
                                </fieldset>
                                <fieldset>
                                    <textarea placeholder="Project Details" rows="5" tabIndex="4" name="message" className="message" id="message"></textarea>
                                </fieldset>
                                <button className="tf-button btn-effect" type="submit"><span className="boder-fade"></span><span className="effect">Send Message</span></button>
                            </form>
                        </div>
                        <div className="col-xl-7  col-md-12">
                            <div className="contact-details" data-aos="fade-left" data-aos-duration="800">
                                <div className="adress wrap-fx">
                                    <div className="location">
                                        <h6>Location</h6>
                                        <ul>
                                            <li>The Moon</li>
                                        </ul>
                                    </div>
                                    <div className="mail">
                                        <h6>Email</h6>
                                        <ul>
                                            <li>info@lunarlotto.xyz</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="flat-map wow fadeIn animated" data-wow-delay="0.3ms" data-wow-duration="1000ms">
                                    <iframe title='map' className="map-content" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50531125.250598714!2d-163.44112236506672!3d39.37491270157947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86de266386b6ec77%3A0x18f66af26201e7e0!2sNASA!5e0!3m2!1sen!2sus!4v1661657340045!5m2!1sen!2sus" width="1720" height="655" allowFullScreen="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;