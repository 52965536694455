// import img1 from '../images/common/team1.jpg';
// import img2 from '../images/common/team2.jpg';
import img3 from '../images/common/team3.png';

const dataTeam = [
    {
        id: 1,
        img: "https://i.seadn.io/gcs/files/00bff3f4f070c65500f0c2f359e6566d.png?auto=format&w=1920",
        name: 'Peter Staker',
        position: 'CTO | Co-Founder',
        ens: "satoshisadboi.eth",
        bio: "Software engineer, technologist, and philosopher — committed to a growth mindset, passionate about lifelong learning, and obsessed with writing code to develop creative solutions and maximize efficiency. Responsible for leading new product initiatives and bringing digital products from concept to market. Web3 enthusiast committed to creating unique experiences, building strong communities, and encouraging creativity.",
        email: "satoshisadboi@lunarlotto.xyz",
        image: "https://i.seadn.io/gcs/files/00bff3f4f070c65500f0c2f359e6566d.png?auto=format&w=1920",
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/SatoshiSadboi'
            },
            {
                id: 2,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/peterjast/'
            },
            {
                id: 3,
                icon: 'fab fa-youtube',
                link: 'https://www.youtube.com/channel/UC0FWp67V8JDhhcLiajL2yjw'
            },
            {
                id: 4,
                icon: 'fa fa-paper-plane',
                link: 'mailto:satoshisadboi@gmail.com'
            },
        ]
    },
    {  
        id: 2,
        img: "https://i.seadn.io/gcs/files/0cedfe274d64ec18a60bbc86a83336b2.png?auto=format&w=1920",
        name: 'Curtis Costella',
        position: 'Artist | Co-Founder',
        ens: "hazypurps.eth",
        bio: "Electrical engineer by day, artist and JPEG collector at night.  Addicted to knowledge and the quest to obtain it.  Jumped headfirst into web3 and NFTs for the technology and the artwork.  Stuck around because of the communities and friendships made over time.  IRL, responsible for designing complex infrastructure and managing projects from design to completion.  Committed to bringing that same level of ambition and attention to detail into web3.",
        email: "hazy_purps@lunarlotto.xyz",
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/hazy_purps'
            },
            {
                id: 2,
                icon: 'fab fa-youtube',
                link: 'https://www.youtube.com/channel/UC0FWp67V8JDhhcLiajL2yjw'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'mailto:hazy_purps@lunarlotto.xyz'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Mike aka "Whiteapeno"',
        position: 'Director of Vibes | Co-Founder',
        ens: "",
        bio: "A loving father and husband who never shies away from hard work, but never forgets to bring the vibes. Mike has been successfully trading NFTs and navigating the web3 space since the beginning of time. Passionate about changing the space for the better and creating unique and interactive experiences.",
        email: "whiteapeno@lunarlotto.xyz",
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/whiteapeno'
            },
            {
                id: 2,
                icon: 'fab fa-youtube',
                link: 'https://www.youtube.com/channel/UC0FWp67V8JDhhcLiajL2yjw'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'mailto:whiteapeno@lunarlotto.xyz'
            },
        ]
    },
]

export default dataTeam;