const dataRoadmap = [
    {
        id: 1,
        time: 'Q4 2022',
        list: [
            {
                text: 'Release website to the public'
            },
            {
                text: 'Establish social media presence'
            },
            {
                text: 'Grow community and Lunar List'
            },
            {
                text: 'Begin announcing official partnerships'
            },
        ],
        positon: 'left'
        // style: 'normal'
    },
    {
        id: 2,
        time: 'Q1 2023',
        list: [
            {
                text: 'Continue building our community and partnerships'
            },
            {
                text: 'Finalize the Lunar List'
            },
            {
                text: 'Lunar Lotto mint - Date: TBA'
            },
            {
                text: 'Token reveal & redeem prizes'
            },
        ],
        positon: 'right',
        // style: 'normal'
    },
    {
        id: 3,
        time: 'Q2 2023',
        list: [
            {
                text: 'Focus on marketing, growth, and scaling Lunar Lotto'
            },
            {
                text: 'Host exclusive giveaways and contests for our High Rollers'
            },
            {
                text: 'Announce date and prize list for second lottery'
            },
            {
                text: 'Establish future timeline and road map and share it with the community'
            }
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q3 2023 & Beyond',
        list: [
            {
                text: 'Make sure to follow us on Twitter and join our discord for future updates.'
            }
        ],
        positon: 'right'
    },
]

export default dataRoadmap;