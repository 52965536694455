import React, { useState, useEffect } from "react";
import "../scss/components/blackjack-app.scss";
import { shuffle } from "lodash";
import Game from "../components/blackjack/Game";
import Five from "../images/blackjack/5-chip.png";
import TwentyFive from "../images/blackjack/25-chip.png";
import OneHundred from "../images/blackjack/100-chip.png";
import FiveHundred from "../images/blackjack/500-chip.png";
import OneThousand from "../images/blackjack/1000-chip.png";
import TenThousand from "../images/blackjack/10k-chip.png";
import FiftyThousand from "../images/blackjack/50k-chip.png";
import OneHundredK from "../images/blackjack/100k-chip.png";
import FiveHundredK from "../images/blackjack/500k-chip.png";
import OneMillion from "../images/blackjack/1m-chip.png";
import PageTitle from '../components/pagetitle';
import LunarList from "../components/lunarlist/LunarList";
import { withAuthenticationRequired } from "@auth0/auth0-react";
const BlackJack = (props) => {
  const deckOfCards = [
        "AH",
        "2H",
        "3H",
        "4H",
        "5H",
        "6H",
        "7H",
        "8H",
        "9H",
        "10H",
        "JH",
        "QH",
        "KH",
        "AC",
        "2C",
        "3C",
        "4C",
        "5C",
        "6C",
        "7C",
        "8C",
        "9C",
        "10C",
        "JC",
        "QC",
        "KC",
        "AD",
        "2D",
        "3D",
        "4D",
        "5D",
        "6D",
        "7D",
        "8D",
        "9D",
        "10D",
        "JD",
        "QD",
        "KD",
        "AS",
        "2S",
        "3S",
        "4S",
        "5S",
        "6S",
        "7S",
        "8S",
        "9S",
        "10S",
        "JS",
        "QS",
        "KS",
      ];
    
      const twoDecks = [...deckOfCards, ...deckOfCards];
      const shuffledDoubleDeck = shuffle(twoDecks);
      const firstFourCards = shuffledDoubleDeck.splice(0, 4);
    
      const [randomizedDecks, setRandomizedDecks] = useState([]);
      // const [props.moolah, setprops.moolah] = useState(props.moolah);
      const [betAmount, setBetAmount] = useState(0);
      const [lockedBet, setLockedBet] = useState(0);
      const [previousBet, setPreviousBet] = useState(0);
      const [dealersCards, setDealersCards] = useState([]);
      const [dealerCount, setDealerCount] = useState(0);
      const [playersCards, setPlayersCards] = useState([]);
      const [playerCount, setPlayerCount] = useState(0);
      const [isBlackjack, setIsBlackJack] = useState(false);
      const [isPlayerBusted, setIsPlayerBusted] = useState(false);
      const [didDouble, setDidDouble] = useState(false);
      const [isDealersTurn, setIsDealersTurn] = useState(false);
      const [isDealerBusted, setIsDealerBusted] = useState(false);
      const [isHandComplete, setIsHandComplete] = useState(true);
      const [winner, setWinner] = useState("");
      const [totalAmountWon, setTotalAmountWon] = useState(0);
    
      const shuffleDeck = () => {
        const newShuffle = shuffle(twoDecks);
        const newFirstFour = newShuffle.splice(0, 4);
        setRandomizedDecks(newShuffle);
        setPlayersCards([newFirstFour[0], newFirstFour[2]]);
        setDealersCards([newFirstFour[1], newFirstFour[3]]);
      };
    
      const startHand = () => {
        if (isHandComplete && lockedBet > 0) {
          shuffleDeck();
          setIsHandComplete(false);
          setWinner("");
          setIsPlayerBusted(false);
          setIsDealerBusted(false);
          setIsDealersTurn(false);
          setPlayerCount(0);
          setDealerCount(null);
          setIsBlackJack(false);
          setPreviousBet(lockedBet);
          setLockedBet(0);
          setDidDouble(false);
        }
      };

      useEffect(() => {
        handleLockedBet();
      }, [betAmount]);

      useEffect(() => {
        if (previousBet > 0) {
          setLockedBet(0);
        }
      }, [previousBet]);
    
      useEffect(()=> {
        if(props.moolah < 5 && betAmount === 0 && lockedBet === 0 && totalAmountWon < 1) {
          alert(`Awhh, sorry ${props.username}! Since Lunar Lotto loves you, we hid some emergency funds for you. Try hitting the reset button 🤫`)
        }
      }, [props.moolah]);

      const resetBets = () => {
        setBetAmount(0);
        setLockedBet(0);
        if (props.moolah < 5 && totalAmountWon < 1 && isHandComplete) {
          restartFresh();
        }
      };
    
      const restartFresh = () => {
        if (props.moolah < 5 && totalAmountWon < 1 && isHandComplete) {
          props.updateChips(10000);
          props.setMoolah(10000);
          setBetAmount(0);
        }
      };
    
      const handScore = (who, hand) => {
        let copyOfHand = [...hand];
        let sortedHand = copyOfHand.sort();
        let tenRegex = /^[JQK]|^10/;
        let numRegex = /^[2-9]/;
        let aceRegex = /^A/;
        let acesLast = [];
        sortedHand.forEach((card) => {
          if (aceRegex.test(card)) {
            acesLast.push(card);
          } else {
            acesLast.unshift(card);
          }
        });
    
        let handCount = 0;
    
        for (let i = 0; i < acesLast.length; i++) {
          if (tenRegex.test(acesLast[i])) {
            handCount += 10;
          } else if (numRegex.test(acesLast[i])) {
            handCount += parseInt(acesLast[i].match(numRegex)[0]);
          } else if (aceRegex.test(acesLast[i])) {
            if (handCount <= 10) {
              handCount += 11;
            } else if (handCount + 11 > 21) {
              handCount += 1;
            }
          }
        }
    
        if (who === "dealer") {
          setDealerCount(handCount);
        }
        if (who === "player") {
          setPlayerCount(handCount);
        }
        return handCount;
      };
    
      const handleHit = () => {
        if (
          playerCount < 21 &&
          !isDealersTurn &&
          winner !== "dealer" &&
          !isBlackjack
        ) {
          setTimeout(() => {
            let currentDeck = [...randomizedDecks];
            let nextCard = currentDeck.splice(0, 1)[0];
            setPlayersCards([...playersCards, nextCard]);
            setRandomizedDecks(currentDeck);
          }, 300);
        }
      };
    
      const handleStay = () => {
        if (!isPlayerBusted && !isDealersTurn) {
          setIsDealersTurn(true);
        }
      };
    
      const handleBet = (bet) => {
        if (lockedBet + bet <= props.moolah && isHandComplete) {
          setLockedBet(lockedBet + bet);
        };
      };
    
      const handleLockedBet = () => {
        if (betAmount > 0) {
          setLockedBet(betAmount);
        }
      };

    
      // const keepSameBet = () => {
      //   if (isHandComplete && props.moolah >= previousBet && previousBet > 0) {
      //     shuffleDeck();
      //     setIsHandComplete(false);
      //     setWinner("");
      //     setIsPlayerBusted(false);
      //     setIsDealerBusted(false);
      //     setIsDealersTurn(false);
      //     setPlayerCount(0);
      //     setDealerCount(null);
      //     setLockedBet(0);
      //     setIsBlackJack(false);
      //     setDidDouble(false);
      //   }
      // };
    
      const handleDouble = () => {
        if (
          !isDealersTurn &&
          playersCards.length === 2 &&
          props.moolah - previousBet >= previousBet
        ) {
          handleHit();
          setTimeout(() => {
            setIsDealersTurn(true);
          }, 1500);
          setDidDouble(true);
        } else {
          alert(`Nice try! You don't have ${previousBet}`)
        }
      };
    
      // const handleSplit = () => {
    
      // }
    
      const clearLockedBet = () => {
        setLockedBet(0);
      };
    
      const dealerHitAgain = () => {
        let tenRegex = /^[JQK]|^10/;
        let numRegex = /^[2-9]/;
        let aceRegex = /^A/;
    
        let nextCardValue;
        let currentDeck = randomizedDecks;
        let nextCard = currentDeck.splice(0, 1)[0];
    
        if (tenRegex.test(nextCard)) {
          nextCardValue = 10;
        } else if (numRegex.test(nextCard)) {
          nextCardValue = parseInt(nextCard.match(numRegex)[0]);
        } else if (aceRegex.test(nextCard)) {
          if (dealerCount <= 10) {
            nextCardValue = 11;
          } else if (dealerCount + 11 > 21) {
            nextCardValue = 1;
          }
        }
        setTimeout(() => {
          setDealersCards([...dealersCards, nextCard]);
          setRandomizedDecks(currentDeck);
          setDealerCount(dealerCount + parseInt(nextCardValue));
        }, 1000);
      };
    
      useEffect(() => {
        handScore("player", playersCards);
      }, [playersCards]);
    
      useEffect(() => {
        handScore("dealer", dealersCards);
      }, [dealersCards]);
    
      // Check for blackjack in the beginning
      useEffect(() => {
        if (playersCards.length === 2 && playerCount === 21 && dealerCount !== 21) {
          setIsBlackJack(true);
          setWinner("player");
          setIsHandComplete("true");
          setIsDealersTurn("true");
        }
    
        if (dealersCards.length === 2 && dealerCount === 21 && playerCount !== 21) {
          setIsBlackJack(true);
          setWinner("dealer");
          setIsHandComplete("true");
          setIsDealersTurn("true");
        }
    
        if (
          playersCards.length === 2 &&
          dealersCards.length === 2 &&
          dealerCount === 21 &&
          playerCount === 21
        ) {
          setWinner("push");
          setIsHandComplete("true");
          setIsDealersTurn("true");
        }
      });
    
      // Player's turn
      useEffect(() => {
        if (playerCount > 21) {
          setWinner("dealer");
          setIsPlayerBusted(true);
          setIsHandComplete(true);
          setIsDealersTurn(true);
        }
      }, [playerCount]);
    
      //Dealer's turn
      useEffect(() => {
        if (
          isDealersTurn &&
          dealerCount < 17 &&
          !isPlayerBusted &&
          winner !== "player"
        ) {
          setTimeout(() => {
            dealerHitAgain();
          }, 1000);
        }
        if (
          isDealersTurn &&
          dealerCount >= 17 &&
          dealerCount <= 21 &&
          !isPlayerBusted
        ) {
          if (dealerCount > playerCount) {
            setWinner("dealer");
            setIsHandComplete(true);
          }
          if (dealerCount < playerCount) {
            setWinner("player");
            setIsHandComplete(true);
          }
          if (dealerCount === playerCount) {
            setWinner("push");
            setIsHandComplete(true);
          }
        }
      }, [isDealersTurn]);
    
      useEffect(() => {
        if (dealerCount > 21) {
          setIsDealerBusted(true);
          setWinner("player");
          setIsHandComplete(true);
        }
    
        if (dealerCount >= 17 && dealerCount < 22 && isDealersTurn) {
          if (dealerCount > playerCount) {
            setWinner("dealer");
            setIsHandComplete(true);
          }
          if (dealerCount < playerCount && !isPlayerBusted) {
            setWinner("player");
            setIsHandComplete(true);
          }
          if (dealerCount === playerCount && !isPlayerBusted) {
            setWinner("push");
            setIsHandComplete(true);
          }
        }
    
        if (dealerCount < 17 && isDealersTurn && !isPlayerBusted) {
          setTimeout(() => {
            dealerHitAgain();
          }, 1000);
        }
      }, [dealerCount]);
    
      // Payout / take losses / push
      useEffect(() => {
        if (winner === "dealer") {
          if (didDouble) {
            setTotalAmountWon(-2 * previousBet);
          } else {
            setTotalAmountWon(-1 * previousBet);
          }
        }
        if (winner === "player" && !isBlackjack) {
          if (didDouble) {
            setTotalAmountWon(previousBet * 2)
          } else {
            setTotalAmountWon(previousBet)
          }
        }
        if (winner === "player" && isBlackjack) {
          setTotalAmountWon(previousBet * 1.5)
        }
        if (winner === "push") {
            setTotalAmountWon(0);
        }
        if (winner === "push" || winner === "dealer" || winner === "player") {
          setPreviousBet(lockedBet)
        }
      }, [winner]);
    
      useEffect(() => {
        if (lockedBet > 0) {
          setBetAmount(0);
        }
      }, [lockedBet]);
    
      useEffect(() => {
        if (isPlayerBusted) {
        }
      }, [isPlayerBusted]);

      const collectWinnings = () => {
        props.setMoolah(props.moolah + totalAmountWon);
        props.updateChips(props.moolah + totalAmountWon);
        resetLayout();
      };
    
      const resetLayout = () => {
        setTotalAmountWon(0);
        setIsDealersTurn(false);
        setWinner("");
        setDealersCards([]);
        setDealerCount(0);
        setPlayersCards([]);
        setPlayerCount(0);
      }

      const whichMessage = (messageType) => {
        const goodMessages = [
          "Nice one! Won",
          "Nailed it! Won",
          "Chicken dinner! Won",
        ];
        const badMessages = [
          "Not this time. Lost",
          "Boooo, lost",
          "Whomp! Whomp! Lost",
        ];
        const blackjack = "Blackjack!"
        const push = "Push!"
        if (messageType === "won") {
          return goodMessages[Math.floor(Math.random() * goodMessages.length)];
        } else if (messageType === "lost") {
          return badMessages[Math.floor(Math.random() * badMessages.length)];
        } else {
          return messageType = "blackjack" ? blackjack : push;
        }
      };
    

      return (
        <>
            <PageTitle title='Lunar Blackjack' />
            <div className="blackjack-rotate">
              <div className="blackjack-phone">
              </div>
              <div className="blackjack-message">
                Please rotate your device!
              </div>
            </div>
            <div className="blackjack-app">
              <header className="roulette-app-header">
              <div className="wheel-history">
                <button
                      className="tf-button-st2 btn-effect"
                      id={betAmount > 0 ? "ready-to-start" : "not-ready"}
                      onClick={resetBets}
                  >
                      RESET
                  </button>
              <h3 className="header-h3">BET: {isHandComplete ? lockedBet : previousBet}</h3>
              {props.moolah >= 1000000 && !props.hasMustard && !lockedBet && (
                <div className="lunar-list-container">
                  <LunarList {...props} />
                </div>
              )}
              </div>
              <div className="header-div right-header">
              <h3 className="header-h3">
              <span className="blackjack-chip-count-text">☽Ξ {props.moolah}</span>
              {/* {isHandComplete && props.moolah <= 0 ? (
                    <button
                    className="tf-button-st2 btn-effect"
                    id={props.moolah < 5 ? "ready-to-start" : "not-ready"}
                    onClick={restartFresh}
                    >
                    RESTART
                    </button>
                ) : null} */}
              </h3>
              </div>
          </header>
            <Game
                randomizedDecks={randomizedDecks}
                playersCards={playersCards}
                dealersCards={dealersCards}
                chipCount={props.moolah}
                setChipCount={props.setMoolah}
                betAmount={betAmount}
                setBetAmount={setBetAmount}
                playerCount={playerCount}
                setPlayerCount={setPlayerCount}
                dealerCount={dealerCount}
                setDealerCount={setDealerCount}
                handleHit={handleHit}
                handleStay={handleStay}
                handleDouble={handleDouble}
                // handleSplit={handleSplit}
                lockedBet={lockedBet}
                handleLockedBet={handleLockedBet}
                isHandComplete={isHandComplete}
                isDealersTurn={isDealersTurn}
                isPlayerBusted={isPlayerBusted}
                isDealerBusted={isDealerBusted}
                didDouble={didDouble}
                startHand={startHand}
                handleBet={handleBet}
                previousBet={previousBet}
                winner={winner}
                isBlackjack={isBlackjack}
                clearLockedBet={clearLockedBet}
            />
        <div className="reset-button-div">
          {winner && winner.length && isHandComplete ? (
            <div className="winnings-div">
              {totalAmountWon > 0 ? 
                isBlackjack ? 
                (
                  <h3>
                    <span className="winnings-div-num">Blackjack! You win {totalAmountWon}.</span>
                  </h3>
                ) : (
                <h3>
                  {whichMessage("won")}
                  <span className="winnings-div-num">{totalAmountWon}</span>!
                </h3>
                )
              : totalAmountWon === 0 ? (
                <h3>
                <span className="winnings-div-num">Push!</span>
              </h3>
                ) : (
                <h3>
                  {whichMessage("lost")}
                  <span className="winnings-div-num">{totalAmountWon}</span>.
                </h3>
              )}
              {props.moolah < 1 && totalAmountWon === 0 && isHandComplete ? (
                <button className="tf-button-st2 btn-effect" onClick={resetLayout}>
                  +10000 Restart
                </button>
              ) : (
                <button
                  className="tf-button-st2 btn-effect"
                  onClick={collectWinnings}
                >
                  {totalAmountWon > 0 ? "COLLECT WINNINGS" : "CLEAR TABLE"}
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
            <div className="game-result-div">
                <section className="betting-options">
                {isHandComplete ? (
                    <div className="inner-betting-options">
                    {props.moolah >= 5 ? (
                        <img
                        src={Five}
                        onClick={() => handleBet(5)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 25 ? (
                        <img
                        src={TwentyFive}
                        onClick={() => handleBet(25)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 100 ? (
                        <img
                        src={OneHundred}
                        onClick={() => handleBet(100)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 500 ? (
                        <img
                        src={FiveHundred}
                        onClick={() => handleBet(500)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 1000 ? (
                        <img
                        src={OneThousand}
                        onClick={() => handleBet(1000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 10000 ? (
                        <img
                        src={TenThousand}
                        onClick={() => handleBet(10000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 50000 ? (
                        <img
                        src={FiftyThousand}
                        onClick={() => handleBet(50000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 100000 ? (
                        <img
                        src={OneHundredK}
                        onClick={() => handleBet(100000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 500000 ? (
                        <img
                        src={FiveHundredK}
                        onClick={() => handleBet(500000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    {props.moolah >= 1000000 ? (
                        <img
                        src={OneMillion}
                        onClick={() => handleBet(1000000)}
                        className="bet-button"
                        ></img>
                    ) : null}
                    </div>
                ) : (
                    <></>
                )}
                <div className="blackjack-buttons">
                  {/* <button
                  className="tf-button-st2 btn-effect"
                  id={betAmount > 0 ? "ready-to-start" : "not-ready"}
                  onClick={handleLockedBet}
                  >
                  BET
                  </button> */}
                  {isHandComplete ? (
                  <button
                      className="tf-button-st2 btn-effect"
                      id={lockedBet > 0 ? "ready-to-start" : "not-ready"}
                      onClick={startHand}
                  >
                      START
                  </button>
                  ) : null}
                </div>
              </section>
            </div>
            </div>
        </>
      );
}

export default withAuthenticationRequired(BlackJack);
