const dataFaq = [
    {
        id: 1,
        title: 'How do I play lunar lotto?',
        content: 'You need to mint a lunar lotto NFT. For a guaranteed chance to mint, earn a spot on the lunar list.',
    },
    {
        id: 2,
        title: 'How do I get lunar listed?',
        content: 'There are lots of ways to win. You can accumulate 1,000,000 Lunar Eth by playing our casino games. Win a jackpot from our lunar lotto slot machine for instant lunar list. Win a contest in our discord or a twitter giveaway. Participate in Lunar Lotto twitter spaces and contribute to the community.',
    },
    {
        id: 3,
        title: 'How can you do a lottery? Is this legal?',
        content: 'After reveal, winners of ETH prizes will need to list their NFT for the price specified to claim their prize. We will then buy the winning NFTs and burn them.',
    },
    {
        id: 4,
        title: 'What can I win?',
        content: 'We will have 4 tiers of Ether prizes. The top tier is 100x mint price. The lowest tier is mint price. There will also be allow list spots for upcoming projects, mystery NFTs, and more TBA. Join our discord and follow us on Twitter to learn more. 75% of tokens are winners, and losing tokens will be eligible to receive consolation prizes, participate in post reveal contests for more opportunities to win, or burn their token for lunar ether, our casino arcade currency that can be used to obtain lunar list for future lotteries!',
    },
]

export default dataFaq;