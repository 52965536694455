const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Set up your wallet',
        text : "Create an ethereum wallet and fund it with ether. You'll need it in order to get lunar listed and win prizes.",
    },
    {
        id: 2,
        numb: '02',
        title: 'Join the community',
        text : 'Follow us on Twitter and join our discord server. Stay informed about giveaways, announcements, and community events.'
    },
    {
        id: 3,
        numb: '03',
        title: "Get Lunar Listed",
        text : 'Get Lunar Listed so you have a guaranteed chance to mint. Any remaining tokens will be available during our public mint phase.'
    },
    {
        id: 4,
        numb: '04',
        title: "Mint Lunar lotto NFTs",
        text : 'Mint your lunar lotto NFTs for a chance to 100x your ether. Keep in mind, 75% of tokens are winners! Good luck!'
    }
]
export default dataWork;