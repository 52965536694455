import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
// import Button from '../../components/button';
import AboutItem from './about-item';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data, currentIndex, setCurrentIndex, isHome, setIsHome} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'What is Lunar Lotto?',
            desc : 'Lunar lotto is a project created by a group of friends that are active members of the NFT space. The problem lately is that most projects tank after reveal and active communities disappear. This project is all about having fun and giving back to the community. We invented our own lottery system for the people. The top tier of prizes includes the chance to 100x the mint price. The lowest tier gets their money back, and the losing tickets will still have opportunities to win consolation prizes and enter into exclusive contests. Our goal is to build a sustainable model, so the majority of what we make from minting, goes straight back to the community.'
        }
    )

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                                {/* <Button title="get Nfts" path='/' /> */}
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;