import React , { useState , useEffect } from 'react';
import { Link , NavLink as RouterNavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import Button from '../button';
import {
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
  
  import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {
    const {
      user,
      isAuthenticated,
      loginWithRedirect,
      logout,
    } = useAuth0();
  
    const logoutWithRedirect = () =>
      logout({
        returnTo: window.location.origin,
      });

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    return (
        <>
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <RouterNavLink to="/"><img src={logo} alt="Lunar Lotto" /></RouterNavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} onClick={handleMenuActive} >
                        <ul id="menu-primary-menu" className="menu">

                            {
                                menus.map((data,idx) => (
                                    <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                    
                                    >
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        
                                    </li>
                                ))
                            }
                            <Nav className="d-none d-lg-block" navbar>
                            <li className="menu-item" onClick={handleMenuActive}>
                                <UncontrolledDropdown nav inNavbar>
                                  <DropdownToggle nav caret id="profileDropDown">
                                    {/* <li className={"menu-item menu-item-has-children"} > */}
                                      <span>CASINO</span>
                                    {/* </li> */}
                                  </DropdownToggle>
                                  <DropdownMenu className="sub-menu">
                                    <DropdownItem
                                      tag={RouterNavLink}
                                      to="/roulette"
                                      className="menu-item"
                                      activeClassName="router-link-exact-active"
                                    >
                                      <span>Roulette</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      tag={RouterNavLink}
                                      to="/blackjack"
                                      className="menu-item"
                                      activeClassName="router-link-exact-active"
                                    >
                                      <span>Blackjack</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                            </Nav>
                      <Nav
                        className="d-lg-none"
                        navbar
                      >
                          <li className="menu-item">
                          <RouterNavLink
                                     to="/roulette"
                                     className="menu-item"
                                     activeClassName="router-link-exact-active"
                          >
                            <span>Roulette</span>
                          </RouterNavLink>
                        </li>
                        <li className="menu-item">
                          <RouterNavLink
                                     to="/blackjack"
                                     className="menu-item"
                                     activeClassName="router-link-exact-active"
                          >
                            <span>Blackjack</span>
                          </RouterNavLink>
                        </li>
                    </Nav>

                    <Nav className="d-none d-lg-block" navbar>
                      <li className="menu-item" styles={{"white-space": "nowrap"}} onClick={handleMenuActive}>
                      {!isAuthenticated && (
                        <NavItem>
                          <Button
                            title="Login with Twitter"
                            id="qsLoginBtn"
                            color="primary"
                            className="btn-margin"
                            onClick={() => loginWithRedirect()}
                          >
                            <span className="boder-fade"></span>                                     
                            <span className="effect">Login with Twitter</span>
                          </Button>
                        </NavItem>
                      )}
                      {isAuthenticated && (
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret id="profileDropDown">
                            <img
                              src={user.picture}
                              alt="Profile"
                              className="nav-user-profile rounded-circle"
                              width="50"
                            />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem header>{user.name}</DropdownItem>
                            <DropdownItem
                              id="qsLogoutBtn"
                              onClick={() => logoutWithRedirect()}
                            >
                            Logout
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </li>
                    </Nav>
                    {!isAuthenticated && (
                      <Nav className="d-lg-none" navbar>
                        <NavItem>
                          <Button
                            title="Login with Twitter"           
                            id="qsLoginBtn"
                            className="tf-button btn-effect"
                            color="primary"
                            block
                            onClick={() => loginWithRedirect({})}
                          >
                            <span className="boder-fade"></span>                                     
                            <span className="effect">Login with Twitter</span>
                          </Button>
                        </NavItem>
                      </Nav>
                    )}
                    {isAuthenticated && (
                      <Nav
                        className="d-lg-none justify-content-between"
                        navbar
                        style={{ minHeight: 170 }}
                      >
                      <li className="menu-item">
                        <NavItem>
                          <span className="user-info">
                            <img
                              src={user.picture}
                              alt="Profile"
                              className="nav-user-profile d-inline-block rounded-circle mr-3"
                              width="50"
                            />
                            <h6 className="d-inline-block">{user.name}</h6>
                          </span>
                        </NavItem>
                        <NavItem>
                          <RouterNavLink
                            to="/#"
                            id="qsLogoutBtn"
                            onClick={() => logoutWithRedirect()}
                          >
                            Log out
                          </RouterNavLink>
                        </NavItem>
                      </li>
                      </Nav>
                    )}
                        </ul>
                    </nav>
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
        </header>
                      </>
    );
}

export default Header;