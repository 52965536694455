import React, { useState } from 'react';
import { Container } from './Container';

const LunarList = ({submit, moolah, setMoolah, updateChips, hasMustard}) => {
    const [wallet, setWallet] = useState("");
    const [errorMessage, setError] = useState("")
    const [isSuccess, setIsSuccess] = useState(false);
    const onSubmit = (event) => {
        event.preventDefault(event);
        if(handleValidation()){ 
            try {
                submit(wallet)
                setIsSuccess(true);
            } catch (err) {
                console.error(err.message);
                setError(err.message);
            }
        }
    };

    const onChange = (e) => {
        setWallet(e.target.value)
    };

    const handleValidation = () => {
        let formIsValid = true;
        if (!wallet.length) {
            formIsValid = false;
            setError("You didn't enter a wallet address, silly!");
        }

        if (wallet.length) {
            if (!wallet.match(/0x[a-fA-F0-9]{40}/)) {
                formIsValid = false;
                setError("Invalid wallet address.");
            }
        }
        return formIsValid;
    }

  return moolah >= 1000000 && !hasMustard ? (
    <div className="lunar-list">
      <Container moolah={moolah} onSubmit={onSubmit} onChange={onChange} errorMessage={errorMessage} isSuccess={isSuccess} hasMustard={hasMustard} />
    </div>
  ) : null;
};

export default LunarList;
