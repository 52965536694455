import React from "react";
import "./Game.css";
import CardBack from "../../images/blackjack/card-back.png";
import AceOfH from "../../images/blackjack/AH.png";
import TwoOfH from "../../images/blackjack/2H.png";
import ThreeOfH from "../../images/blackjack/3H.png";
import FourOfH from "../../images/blackjack/4H.png";
import FiveOfH from "../../images/blackjack/5H.png";
import SixOfH from "../../images/blackjack/6H.png";
import SevenOfH from "../../images/blackjack/7H.png";
import EightOfH from "../../images/blackjack/8H.png";
import NineOfH from "../../images/blackjack/9H.png";
import TenOfH from "../../images/blackjack/10H.png";
import JackOfH from "../../images/blackjack/JH.png";
import QueenOfH from "../../images/blackjack/QH.png";
import KingOfH from "../../images/blackjack/KH.png";
import AceOfC from "../../images/blackjack/AC.png";
import TwoOfC from "../../images/blackjack/2C.png";
import ThreeOfC from "../../images/blackjack/3C.png";
import FourOfC from "../../images/blackjack/4C.png";
import FiveOfC from "../../images/blackjack/5C.png";
import SixOfC from "../../images/blackjack/6C.png";
import SevenOfC from "../../images/blackjack/7C.png";
import EightOfC from "../../images/blackjack/8C.png";
import NineOfC from "../../images/blackjack/9C.png";
import TenOfC from "../../images/blackjack/10C.png";
import JackOfC from "../../images/blackjack/JC.png";
import QueenOfC from "../../images/blackjack/QC.png";
import KingOfC from "../../images/blackjack/KC.png";
import AceOfD from "../../images/blackjack/AD.png";
import TwoOfD from "../../images/blackjack/2D.png";
import ThreeOfD from "../../images/blackjack/3D.png";
import FourOfD from "../../images/blackjack/4D.png";
import FiveOfD from "../../images/blackjack/5D.png";
import SixOfD from "../../images/blackjack/6D.png";
import SevenOfD from "../../images/blackjack/7D.png";
import EightOfD from "../../images/blackjack/8D.png";
import NineOfD from "../../images/blackjack/9D.png";
import TenOfD from "../../images/blackjack/10D.png";
import JackOfD from "../../images/blackjack/JD.png";
import QueenOfD from "../../images/blackjack/QD.png";
import KingOfD from "../../images/blackjack/KD.png";
import AceOfS from "../../images/blackjack/AS.png";
import TwoOfS from "../../images/blackjack/2S.png";
import ThreeOfS from "../../images/blackjack/3S.png";
import FourOfS from "../../images/blackjack/4S.png";
import FiveOfS from "../../images/blackjack/5S.png";
import SixOfS from "../../images/blackjack/6S.png";
import SevenOfS from "../../images/blackjack/7S.png";
import EightOfS from "../../images/blackjack/8S.png";
import NineOfS from "../../images/blackjack/9S.png";
import TenOfS from "../../images/blackjack/10S.png";
import JackOfS from "../../images/blackjack/JS.png";
import QueenOfS from "../../images/blackjack/QS.png";
import KingOfS from "../../images/blackjack/KS.png";

export default function Game({
  randomizedDecks,
  playersCards,
  dealersCards,
  chipCount,
  setChipCount,
  betAmount,
  setBetAmount,
  playerCount,
  setPlayerCount,
  dealerCount,
  setDealerCount,
  handleHit,
  handleStay,
  handleDouble,
  lockedBet,
  handleLockedBet,
  isHandComplete,
  isDealersTurn,
  isPlayerBusted,
  isDealerBusted,
  didDouble,
  startHand,
  handleBet,
  previousBet,
  winner,
  isBlackjack,
  clearLockedBet,
}) {
  const whichImages = (who, cards) => {
    return cards.map((card) => {
      let findImage;
      switch (card) {
        case "AH":
          findImage = AceOfH;
          break;
        case "2H":
          findImage = TwoOfH;
          break;
        case "3H":
          findImage = ThreeOfH;
          break;
        case "4H":
          findImage = FourOfH;
          break;
        case "5H":
          findImage = FiveOfH;
          break;
        case "6H":
          findImage = SixOfH;
          break;
        case "7H":
          findImage = SevenOfH;
          break;
        case "8H":
          findImage = EightOfH;
          break;
        case "9H":
          findImage = NineOfH;
          break;
        case "10H":
          findImage = TenOfH;
          break;
        case "JH":
          findImage = JackOfH;
          break;
        case "QH":
          findImage = QueenOfH;
          break;
        case "KH":
          findImage = KingOfH;
          break;
        case "AC":
          findImage = AceOfC;
          break;
        case "2C":
          findImage = TwoOfC;
          break;
        case "3C":
          findImage = ThreeOfC;
          break;
        case "4C":
          findImage = FourOfC;
          break;
        case "5C":
          findImage = FiveOfC;
          break;
        case "6C":
          findImage = SixOfC;
          break;
        case "7C":
          findImage = SevenOfC;
          break;
        case "8C":
          findImage = EightOfC;
          break;
        case "9C":
          findImage = NineOfC;
          break;
        case "10C":
          findImage = TenOfC;
          break;
        case "JC":
          findImage = JackOfC;
          break;
        case "QC":
          findImage = QueenOfC;
          break;
        case "KC":
          findImage = KingOfC;
          break;
        case "AD":
          findImage = AceOfD;
          break;
        case "2D":
          findImage = TwoOfD;
          break;
        case "3D":
          findImage = ThreeOfD;
          break;
        case "4D":
          findImage = FourOfD;
          break;
        case "5D":
          findImage = FiveOfD;
          break;
        case "6D":
          findImage = SixOfD;
          break;
        case "7D":
          findImage = SevenOfD;
          break;
        case "8D":
          findImage = EightOfD;
          break;
        case "9D":
          findImage = NineOfD;
          break;
        case "10D":
          findImage = TenOfD;
          break;
        case "JD":
          findImage = JackOfD;
          break;
        case "QD":
          findImage = QueenOfD;
          break;
        case "KD":
          findImage = KingOfD;
          break;
        case "AS":
          findImage = AceOfS;
          break;
        case "2S":
          findImage = TwoOfS;
          break;
        case "3S":
          findImage = ThreeOfS;
          break;
        case "4S":
          findImage = FourOfS;
          break;
        case "5S":
          findImage = FiveOfS;
          break;
        case "6S":
          findImage = SixOfS;
          break;
        case "7S":
          findImage = SevenOfS;
          break;
        case "8S":
          findImage = EightOfS;
          break;
        case "9S":
          findImage = NineOfS;
          break;
        case "10S":
          findImage = TenOfS;
          break;
        case "JS":
          findImage = JackOfS;
          break;
        case "QS":
          findImage = QueenOfS;
          break;
        case "KS":
          findImage = KingOfS;
          break;
      }
      if (who === "dealer" && parseInt(cards.indexOf(card)) === 1) {
        return (
          <img
            className="card-img"
            alt={card}
            src={isDealersTurn ? findImage : CardBack}
          ></img>
        );
      }
      return (
        <img
          className={
            didDouble && cards.indexOf(card) === 2 && who === "player"
              ? "sideways-double-card"
              : "card-img"
          }
          alt={card}
          src={findImage}
        ></img>
      );
    });
  };

  return (
    <div className="game-div">
      <div className="dealers-cards-div">
        <h1 className="count">
          {isDealersTurn ? dealerCount : null}
        </h1>
        {whichImages("dealer", dealersCards)}
      </div>
      <div className="chip-or-message">
        {lockedBet > 0 || !isHandComplete ? (
          <div className="empty-chip-container">
            <div className="locked-bet-amount">
              {isHandComplete ? lockedBet : previousBet}
            </div>
            {/* <img
              className="locked-bet-img"
              onClick={clearLockedBet}
              src={BlankChip}
            ></img> */}
          </div>
        ) : null}
      </div>
      <div className="players-cards-div">
        {whichImages("player", playersCards)}
        <h1 className="count">
          {playerCount > 0 ? playerCount : null}
        </h1>
      </div>
      <section className="gameplay-options">
        {!isHandComplete ? (
          <>
            <button
              className="tf-button-st2 btn-effect"
              id={
                !isDealersTurn && playerCount < 21
                  ? "ready-to-start"
                  : "not-ready"
              }
              onClick={handleHit}
            >
              Hit
            </button>
            <button
              className="tf-button-st2 btn-effect"
              id={
                !isDealersTurn &&
                playerCount <= 21 &&
                !didDouble
                  ? "ready-to-start"
                  : "not-ready"
              }
              onClick={handleStay}
            >
              Stay
            </button>
            <button
              className="tf-button-st2 btn-effect"
              id={
                !isDealersTurn &&
                playersCards.length === 2 &&
                chipCount >= previousBet
                  ? "ready-to-start"
                  : "not-ready"
              }
              onClick={handleDouble}
            >
              Double
            </button>
            {/* <button onClick={handleSplit}>Split</button> */}
          </>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
}
