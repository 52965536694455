
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import './assets/font/font-awesome.css'
import history from "./utils/history";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./pages/HomeOne";
import TeamDetail from "./pages/TeamDetail";
import Community from "./pages/Community";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import RoadMapOne from "./pages/RoadMapOne";
import BlackJack from "./pages/BlackJack";
import Roulette from "./pages/Roulette";
import axios from 'axios';

function App() {
  const [wookies, setWookies] = useState({});
  const [moolah, setMoolah] = useState(0); // contributiation yees
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHome, setIsHome] = useState(false);

  const server = process.env.REACT_APP_SERVER;

  useEffect(() => {
    AOS.init({
      duration : 2000
    }); 
  }, []);

  const {
    user,
    isAuthenticated,
    error,
  } = useAuth0();

  useEffect(()=> {
    if(isAuthenticated) {
      try{
        handleSignup(user.nickname, user.name, user.sub);
      } catch (err) {
      }
    }
  }, [user]);

  useEffect(()=> {
    reUp();
  }, [wookies, moolah])

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const reUp = async () => {
    if((wookies && !wookies.myMemes)) {
      try {
        const axiosConfig = {
          headers: { Authorization: `Bearer ${wookies.token}` }
        };
        const money = moolah > 0 ? moolah.toString() : '10000';
        const results = await axios.post(`${server}/meme`, { uploaderId: wookies.userId, username: wookies.username, imageUrl: money}, axiosConfig);
        setMoolah(parseInt(results.data.imageUrl));
      } catch (err) {
      }
    }
  }

	const handleSignup = async (username, email, password) => {
		try {
			const server = process.env.REACT_APP_SERVER;
			const results = await axios.post(`${server}/signup`, { username, email, password });
      handleLoginNoMoolah(results.data.username, password);
		} catch (err) {
      handleLogin(username, password);
		}
	};

  const handleLoginNoMoolah = async (username, password) => {
    try {
      const server = process.env.REACT_APP_SERVER;
      const results = await axios.post(`${server}/signin`, {}, {auth:{username: username, password: password}});
      const config = {
        headers: { Authorization: `Bearer ${results.data.token}` }
      };
      const memes = [];
      const meme = { uploaderId: results.data.user.id, username: username, imageUrl: "10000" }
      const response = await axios.post(`${server}/meme`, meme, config);
      if (response) {
        memes.push(response.data);
        setWookies({isAuthenticated: true, token: results.data.token, myMemes: memes, userId: results.data.user.id, username: username});
        setMoolah(parseInt(meme.imageUrl));
      }
    } catch (err) {
    }
  }

  const handleLogin = async(username, password) => {
    try {
      const server = process.env.REACT_APP_SERVER;
      const results = await axios.post(`${server}/signin`, {}, {auth:{username: username, password: password}});
      const config = {
        headers: { Authorization: `Bearer ${results.data.token}` }
      };
      const response = await axios.get(`${server}/meme/${results.data.user.id}`, config);
      const images = response.data.reverse();
      setWookies({isAuthenticated: true, token: results.data.token, myMemes: images, userId: results.data.user.id, username: username});
      if (images && images.length) {
        setMoolah(parseInt(images[0].imageUrl) <= 0 ? 10000 : parseInt(images[0].imageUrl));
      } else {
        reUp();
      }
    } catch (err) {
    }
  }

  const updateChips = async (dollas) => {
    try{
      const axiosConfig = {
        headers: { Authorization: `Bearer ${wookies.token}` }
      };
      if (wookies.myMemes && wookies.myMemes.length) {
        await axios.put(`${server}/meme/${wookies.myMemes[0]._id}`, { uploaderId: wookies.userId, username: wookies.username, imageUrl: dollas.toString() }, axiosConfig);
      } else {
        const memes = [];
        const response = await axios.post(`${server}/meme`, { uploaderId: wookies.userId, username: wookies.username, imageUrl: dollas.toString() }, axiosConfig);
        memes.push(response.data);       
        setWookies({...wookies, myMemes: memes});
      }
    } catch(err) {

    }
  }

  const submit = async (ketchup) => {
    try {
      if(wookies && wookies.myMemes && wookies.myMemes.length) {
        const axiosConfig = {
          headers: { Authorization: `Bearer ${wookies.token}` }
        };
        const newMoney = moolah - 1000000;
        const response = await axios.post(`${server}/meme`, {uploaderId: wookies.userId, username: wookies.username, imageUrl: newMoney.toString(), wallet: ketchup, hasKetchup: true}, axiosConfig);
        setMoolah(parseInt(newMoney));
        setWookies({myMemes: wookies.myMemes.unshift(response.data), ...wookies});
      }
    } catch (err) {
    }
  }

  const username = user?.nickname;
  
  return(
    <>
      <BrowserRouter history={history}>
          <Header />
          <Switch>
            <Route path='/about' component={() => Community({isHome, setIsHome, currentIndex, setCurrentIndex})} />
            <Route path='/faq' component={FAQ} />
            <Route path='/road-map' component={RoadMapOne} />
            <Route path='/contact' component={Contact} />
            <Route path='/team-details' component={() => TeamDetail({isHome, setIsHome, currentIndex, setCurrentIndex})} />
            <Route path="/roulette" component={() => Roulette({moolah, setMoolah, updateChips, submit})} />
            <Route path="/blackjack" component={() => BlackJack({moolah, setMoolah, updateChips, username, submit})} />
            <Route path="/" component={() => Home({isHome, setIsHome, currentIndex, setCurrentIndex})} />
          </Switch>
          <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
