const dataAbout = [
    {
        id: 1,
        title: '4444 randomly generated lottery tickets',
        desc: 'As the first official lottery of web3, we value full transparency. Each unique lottery ticket will be randomly generated and have the same odds of winning as any other ticket.',
    },
    {
        id: 2,
        title: 'Top prizes include 100x mint price in Ether',
        desc: 'Everyone is looking for that next project to 10x. Lunar Lotto guarantees that the luckiest among you will win 100x the price to mint.',
    },
    {
        id: 3,
        title: '75% chance to win',
        desc: 'Why gamble on another NFT play that is probably going to 0? We guarantee that 75% of all tickets are winners. Our lowest tier of winners will simply win their money back.',
    },
]

export default dataAbout;