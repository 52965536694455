import React, { useState } from 'react';
import PageTitle from '../components/pagetitle';
// import {Link} from 'react-router-dom';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
// import { data } from 'autoprefixer';

function TeamDetail(props) {
    const {isHome, setIsHome, currentIndex, setCurrentIndex} = props;

    return (
        <div id="squad">
            <PageTitle title='Pit Bosses' />

            <section className="tf-section team-detail ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="image-details" data-aos="fade-right" data-aos-duration="800">
                                <img src={dataTeam[currentIndex].img} alt={dataTeam[currentIndex].name} />
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="info-detail" data-aos="fade-left" data-aos-duration="800">
                                <p className="sub">{dataTeam[currentIndex].position}</p>
                                <h4 className="name">{dataTeam[currentIndex].name}</h4>
                                <div className="box">
                                    <div className="h7">{dataTeam[currentIndex].ens}</div>
                                    <p>{dataTeam[currentIndex].bio}</p>
                                </div> 
                                <div className="box contact">
                                    <div className="h7">Contact</div>
                                    <p>{dataTeam[currentIndex].name}</p>
                                    <p>{dataTeam[currentIndex].email}</p>
                                </div> 
            

                                <ul className="social">
                                    {
                                        dataTeam[currentIndex].listsocial.map(icon => (
                                            <li key={icon.id}>
                                                <a href={icon.link} target="_blank" rel="noreferrer"><i className={icon.icon}></i></a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Team data={dataTeam} setCurrentIndex={setCurrentIndex} isHome={isHome} setIsHome={setIsHome} currentIndex={currentIndex} />

        </div>
    );
}

export default TeamDetail;