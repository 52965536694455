import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "react-router-dom";
import './styles.scss';

TeamItem.propTypes = {
  item: PropTypes.object,  
};

function TeamItem(props) {
    const {item, isHome} = props;
    return (
        <div className="team-box">
            <div className="image">
                <img src={item.img} alt="Squad" />
            </div>
            <div className="content">
                <div className="h8">{isHome ? (<Link to="/about">{item.name}</Link>):(<AnchorLink href="#squad">{item.name}</AnchorLink>)}</div>
                <p>{item.position}</p>
                <ul className="social">
                    {
                        item.listsocial.map(icon => (
                            <li key={icon.id}>
                                <a href={icon.link} target="_blank" rel="noreferrer"><i className={icon.icon}></i></a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default TeamItem;