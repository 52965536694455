import React from 'react';

export const Form = ({ onSubmit, onChange, errorMessage, isSuccess }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="Eth Address">Ξ Wallet Address</label>
        <input
          type="text"
          className="form-control"
          id="wallet"
          placeholder="0x0000000000..."
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <button className="form-control btn btn-primary" type="submit">
          Submit
        </button>
      </div>
      {errorMessage ? ( <div className="error-message">{errorMessage}</div> ) : null}
      {isSuccess ? ( <div className="success-message"><span>Wallet successfully added to the Lunar List!</span></div>) : null}
    </form>
  );
};

export default Form;