import bgImg1 from '../images/background/bg-1.jpg';
// import bgImg2 from '../images/background/bg-3.jpg';
// import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/itemslider.png';

const dataSlider = [
    {
        id: 1,
        title: 'Lunar Lotto',
        desc : "Lunar Lotto is the first official lottery of web3. The collection features 4444 lottery ticket NFTs randomly generated on the ethereum blockchain.",
        bgImg: bgImg1,
        img : img1
    }
]

export default dataSlider;